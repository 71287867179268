import fetch from 'isomorphic-fetch';
import { API_URL } from '../config/config';
import featureSwitch from './featureSwitch';

function getOriginHeader() {
  if (process.env.GATSBY_BRAND) {
    return `platform-v1-${process.env.GATSBY_BRAND}`;
  }
  return 'site';
}

export function getJwtToken() {
  if (featureSwitch('auth_shops')) {
    return typeof localStorage !== 'undefined' ? localStorage.getItem('token') : undefined;
  }
  return typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('token') : undefined;
}

function setJwtToken(token) {
  if (featureSwitch('auth_shops') && typeof localStorage !== 'undefined') {
    localStorage.setItem('token', token);
    return;
  }
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem('token', token);
  }
}

export function removeJwtToken() {
  if (featureSwitch('auth_shops') && typeof localStorage !== 'undefined') {
    localStorage.removeItem('token');
    return;
  }
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.removeItem('token');
  }
}

export default function callApi(endpoint, method = 'get', body) {
  const jwtToken = getJwtToken();
  return fetch(`${API_URL}/${endpoint}`, {
    headers: {
      'Content-Type': 'application/json',
      'Origin-tilli': getOriginHeader(),
      ...(jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}),
    },
    method,
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status === 204) {
        return { json: {}, response };
      }
      return response.json()
        .then((json) => ({ json, response }))
        .catch(() => ({ json: {}, response }));
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }

      if (json.brandToken) setJwtToken(json.brandToken);

      return json;
    });
}
